<template>
  <div class="myHistory">
    <van-nav-bar title="历史记录" @click-left="onClickLeft" :border="false">
      <template #left>
        <svg-icon iconClass="leftArrow" class="leftArrow"></svg-icon>
      </template>
    </van-nav-bar>
    <div class="video-content">
      <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh" :refreshing="refreshing" :isNoData="isNoData" :firstLoading="firstLoading">
        <div class="video-item" v-for="(item,index) in mediaInfos" :key="index" @click="onJumpVideo(item)">
          <div class="video-cover-box">
            <ImgDecypt :src="item.videoCover" class="video-cover" />
            <div class="video-info-timers flex-between">
              <div class="left">
                <span class="left-play"></span>{{item.watchTimes|watchCount}}
              </div>
              <div class="right">{{item.playTime|videotime}}</div>
            </div>
            <CoveLabel class="cust_label" :item="item" />
          </div>
          <div class="video-info">
            <h2>{{item.content}}</h2>
            <div class="video-tag-box" v-if="item.flags">
              <div class="video-tag" v-for="(tag,index) in item.flags" :key="index">
                <span>{{tag}}</span>
              </div>
            </div>
          </div>
        </div>
      </Pullrefresh>
    </div>
  </div>
</template>
<script>
import Pullrefresh from "@/views/widget/PullRefresh.vue";
import CoveLabel from "@/components/CoveLabel/index.vue";
import { jumpVideo } from "@/utils/index.js";
import { queryHistoryVideo } from "@/utils/index.js";
export default {
  components: {
    Pullrefresh,
    CoveLabel,
  },
  data() {
    return {
      loading: false, //下滑loading
      finished: false, //数据是否加载完毕
      refreshing: false, //下拉下载loading
      isNoData: false, //数据是否为空
      firstLoading: true, //是否第一次加载
      mediaInfos: [],
      page: 1,
      size: 10,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    //上滑加载
    onLoad() {
      this.page++;
      this.getData();
    },
    //刷新
    onRefresh() {
      this.refreshing = true;
      this.finished = false;
      this.page = 1;
      this.mediaInfos = [];
      this.getData();
    },
    //获取数据
    async getData() {
      this.loading = true;
      let ret = await queryHistoryVideo(this.page, this.size);

      this.loading = false;
      this.refreshing = false;
      this.firstLoading = false;

      let mediaInfos = ret;
      this.mediaInfos = this.mediaInfos.concat(mediaInfos);

      if (mediaInfos.length == 0 && this.page == 1) {
        this.isNoData = true;
        return;
      }
      if (mediaInfos.length < this.size) {
        this.finished = true;
      }
    },
    onJumpVideo(item) {
      jumpVideo(item);
    },
    //返回
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.myHistory {
  height: 100%;
  width: 100%;
  .leftArrow {
    font-size: 16px;
  }
  .van-nav-bar__title {
    font-size: 16px;
  }
  /deep/ .van-nav-bar__content {
    background: $vermillion;
    color: #fff;
    border-bottom: 1px solid #d6d7d6;
  }
  /deep/ .van-nav-bar__title {
    color: #fff;
  }
  .video-content {
    overflow-y: auto;
    padding: 0 10px;
    height: calc(100% - 40px);
    -webkit-overflow-scrolling: touch;
    .video-item {
      display: flex;
      width: 100%;
      height: 107px;
      flex-direction: row;
      margin: 10px 0;
      .video-cover-box {
        flex: 1;
        position: relative;
        .video-cover {
          width: 100%;
          height: 100%;
          overflow: hidden;
          /deep/ img {
            border-radius: 6px;
          }
        }
      }
      .video-info {
        flex: 1;
        height: 107px;
        padding-left: 10px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        h2 {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; //多行在这里修改数字即可
          /* autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          font-size: 16px;
          font-weight: 400;
        }
        .video-tag-box {
          display: flex;
          span {
            padding-right: 5px;
            color: #6a6b66;
            font-size: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
//图标
.cust_label {
  position: absolute;
  top: 0;
  right: 0;
}

//播放次数 时间
.cover-box {
  position: relative;
}
.video-info-timers {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 26px;
  align-items: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  padding: 4px 8px;
  box-sizing: border-box;
  font-size: 10px;
  color: #ffffff;
  .left {
    display: flex;
    align-items: center;
    .left-play {
      display: inline-block;
      margin-right: 3px;
      width: 10px;
      height: 10px;
      background: url("../../../assets/png/play_icon.png") center center
        no-repeat;
      background-size: 100%;
    }
  }
}
</style>